<script>
  import linkMixin from '@/mixins/linkMixin'
  import BaseCard from '@/components/base/BaseCard'

  /**
   * A card that can be selected thanks to a button in its footer, which emits a `select` event when clicked.
   */
  export default {
    extends: BaseCard,
    mixins: [linkMixin],
    props: {
      /** Whether the card is selected. A selected card has an outline, and when using the default button, it reads “Selected” instead of “Select” and contains a checkmark icon. */
      selected: {
        type: Boolean,
        default: false,
      },
      /** Whether the card is disabled. A disabled card has a grayed out button and cannot be focused or selected. */
      disabled: {
        type: Boolean,
        default: false,
      },
      /** Whether the quantity selector should be displayed in place of a button. */
      showQuantitySelector: {
        type: Boolean,
        default: false,
      },
      /** The quantity to display in the quantity selector. Only used in the quantity selector. */
      quantityInSelector: {
        type: Number,
        default: 0,
      },
    },
    computed: {
      propsToPassDown() {
        const { selected, ...propsToPassDown } = this.$props
        return propsToPassDown
      },
      quantityButtonClasses() {
        return 'flex justify-center items-center w-25px h-25px flex-shrink-0 rounded-full transition active:transition-none duration-200'
      },
      increaseButtonEnabled() {
        return this.quantityInSelector < 10
      },
    },
    methods: {
      select() {
        /** Emitted when the “Select” button is clicked. */
        const action = this.selected ? 'removeFromCart' : 'select'
        this.$emit(action)
      },
    },
  }
</script>

<template>
  <BaseCard
    has-border
    v-bind="{ ...propsToPassDown, ...$attrs }"
    class="cursor-pointer md:min-h-80"
    @clickDetails="$emit('clickDetails')"
    v-on="$listeners"
  >
    <template v-if="$scopedSlots.default" v-slot>
      <slot />
    </template>

    <template v-if="$scopedSlots.image" v-slot:image>
      <slot name="image" />
    </template>

    <template v-if="$scopedSlots.tags" v-slot:tags>
      <slot name="tags" />
    </template>

    <template v-if="$scopedSlots.rank" v-slot:rank>
      <slot name="rank" />
    </template>

    <template v-if="!$scopedSlots.default && $scopedSlots.price" v-slot:price>
      <slot name="price" />
    </template>

    <template v-if="!$scopedSlots.default && $scopedSlots.kicker" v-slot:kicker>
      <slot name="kicker" />
    </template>

    <template v-if="!$scopedSlots.default && $scopedSlots.title" v-slot:title>
      <slot name="title" />
    </template>

    <template v-if="!$scopedSlots.default && $scopedSlots.subtitle" v-slot:subtitle>
      <slot name="subtitle" />
    </template>

    <template v-if="!$scopedSlots.default && $scopedSlots.description" v-slot:description>
      <slot name="description" />
    </template>

    <template v-slot:footer>
      <div v-if="showQuantitySelector" class="flex justify-center">
        <div class="flex items-center flex-shrink-0">
          <button class="p-4 focus:shadow-none" @click="$emit('removeFromCart')">
            <BaseIcon
              label="Decrease quantity"
              :class="`${quantityButtonClasses} border border-primary hover:bg-gray-opacity4 hover:text-primary`"
            >
              <IconMinus class="w-3 h-3" />
            </BaseIcon>
          </button>
          <div class="text-primary font-bold text-lg w-5 text-center">
            {{ quantityInSelector }}
          </div>
          <button
            :disabled="!increaseButtonEnabled"
            :class="`p-4 focus:shadow-none ${!increaseButtonEnabled ? 'cursor-default' : ''}`"
            @click="$emit('addToCart')"
          >
            <BaseIcon
              label="Increase quantity"
              :disabled="!increaseButtonEnabled"
              :class="`${quantityButtonClasses} border border-gray-opacity42 ${
                increaseButtonEnabled
                  ? 'bg-primary text-white hover:bg-primary-bright active:bg-primary-dark'
                  : ' text-gray-opacity42'
              }`"
            >
              <IconPlusThick class="w-3 h-3" />
            </BaseIcon>
          </button>
        </div>
      </div>
      <button
        v-else
        :disabled="disabled"
        :class="`pt-14px px-11px md:px-2 pb-15px w-full z-10 focus:shadow-none ${
          disabled ? 'cursor-default' : ''
        }`"
        data-cy="select"
        @click="select"
      >
        <BaseButtonPill color="primary" full-width :disabled="disabled" :class="`font-semibold`">
          <!-- @slot Optional. The button’s label. Defaults to “Select” or “Selected” depending on the value of the `selected` prop. Should contain text and/or `BaseIcon` components. -->
          <slot name="buttonLabel">
            <template v-if="selected && !disabled">
              <BaseIcon :size="5" class="-ml-2 mr-2 flex-shrink-0">
                <IconCheckmark />
              </BaseIcon>
              Selected
            </template>
            <template v-else>Select</template>
          </slot>
        </BaseButtonPill>
      </button>
    </template>
  </BaseCard>
</template>
